<template>
    <div>
        <img style="position: absolute;width: 100%; height: 100%" src="@/assets/images/error.jpg" alt="" />
    </div>
</template>
<script>
export default{
    name:'Error',
    data(){
        return{

        }
    }
}

</script>